import { ReactElement } from 'react';
import Head from 'next/head';

type Props = {
  title?: string;
  description?: string;
  type?: string;
  imageUrl?: string;
  url?: string;
  keywords?: Array<string | null>;
};

const SITE_KEYWORDS = ['買房', '買屋', '賣屋', '賣屋', 'FUNWOO', '德載國際不動產', '曾意婷', 'Nancy Tseng'];

export default function OpenGraph({
  title = 'FUNWOO 德載國際不動產',
  description = 'FUNWOO 團隊致力於結合科技，設計與專業房仲來媒合買賣雙方，創造賓至如歸的房地產交易過程',
  type = 'funwoo.home',
  imageUrl = `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-image-home-l.jpeg`,
  url = 'https://funwoo.com.tw/',
  keywords = [],
}: Props): ReactElement {
  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta key='og:title' property='og:title' content={title} />
      <meta key='og:description' property='og:description' content={description} />
      <meta key='og:type' property='og:type' content={type} />
      <meta key='og:image' property='og:image' content={imageUrl} />
      <meta key='og:url' property='og:url' content={url} />
      <meta property='og:site_name' content='FUNWOO 德載國際不動產' />
      <meta
        itemProp='keywords'
        // @ts-ignore
        // needs to figure out why ConcatArray<string> doesn't accept string as argument
        content={Array.from(new Set(SITE_KEYWORDS.concat(keywords)))
          .filter(Boolean)
          .join(',')}
      />
    </Head>
  );
}
