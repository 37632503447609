export const isSet = <Whatever>(whatever: Whatever | null | undefined | string): whatever is Whatever =>
  whatever !== null &&
  whatever !== undefined &&
  typeof whatever !== 'undefined' &&
  whatever !== void 0 &&
  whatever !== '';

export const isNotSet = <Whatever>(whatever: Whatever | null | undefined | string): whatever is null | undefined =>
  whatever === null ||
  whatever === undefined ||
  typeof whatever === 'undefined' ||
  whatever === void 0 ||
  whatever == '';

export const isString = <Whatever>(whatever: Whatever | string): whatever is string => typeof whatever === 'string';

export const isEmptyString = (string: string = '') => string.length === 0;

export const isNotEmptyString = (string: string = '') => string.length !== 0;

export const isEmptyArray = (array: Array<any> = []) => array.length === 0;

export const isNotEmptyArray = (array: Array<any>) => array.length !== 0;

export const isEmptyObject = (object: Record<any, any>) => Object.keys(object).length === 0;

export const isTrue = (whatever: any): whatever is true => typeof whatever === 'boolean' && whatever === true;

export const isFalse = (whatever: any): whatever is false => typeof whatever === 'boolean' && whatever === false;

export const isNumber = <Whatever>(what: number | Whatever): what is number => {
  return typeof what === 'number';
};
