import FunwooAPI from '@/swagger';

export const locationData = FunwooAPI.systemApi.getLocationData().then(({ data }) => data);

const mirror = (list: any) => list.reduce((a: any, c: any) => ({ ...a, [c]: c }), {});

export const APP_CONTENT_ID = 'app-content';

export enum TEMPLATE_CATEGORY {
  'ALL' = 'ALL',
  'LISTING_PRESENTATION' = 'LISTING_PRESENTATION',
  'SOCIAL_MEDIA' = 'SOCIAL_MEDIA',
  'ONE_PAGE_FLYER' = 'ONE_PAGE_FLYER',
}

export const CORPORATE_SEARCH_KEYWORD = [
  'corporate_campus',
  'corporate_office',
  'amazon office',
  'microsoft office',
  'google office',
  'facebook office',
  'boeing office',
  'tableau office',
  'expedia Group office',
  'sap Concur office',
  'redfin office',
  'starbucks office',
];

export const BUILD_TYPE_OPTIONS = [
  '公寓(5樓含以下無電梯)',
  '住宅大樓(11層含以上有電梯)',
  '華廈(10層含以下有電梯)',
  '透天厝',
  '店面(店鋪)',
  '辦公商業大樓',
  '套房(1房1廳1衛)',
  '工廠',
  '廠辦',
  '農舍',
  '倉庫',
  '其他',
];
export const GARAGE_TYPE_OPTIONS = ['坡道平面', '一樓平面', '升降平面', '坡道機械', '升降機械', '塔式車位', '其他', ''];
export const TABLE_HEADER = ['地址', '房型', '總坪數', '總價', '單價', '屋齡', '格局'];
export const NAV = mirror([
  'ADS',
  'LISTING_PRESENTATION',
  'PUBLICATION_MANAGER',
  'SOCIAL_MEDIA',
  'TEMPLATE_MANAGER',
  'ADMIN',
  'CMA',
  'LISTINGS',
]);
export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};
export const GLOBAL = mirror([
  'pref',
  // book{} 含 pages[] 詳細資料
  'selectedBook',
  // pageId: string
  'selectedPage',
  // templateId: string
  'selectedTemplate',

  // publication manager 用
  'selectedBookProtoId',
  'selectedBookProtoPageId',

  // 主導覽選單內目前選取的頁面
  'isMenuOpen',
  'selectedSection',

  // cache，不含每本書的 pages[]，僅供列表顯示用
  'books',
  // cache, { key: id, value: template{} }
  'templates',
  // 出版品的 proto，目前只有兩種 listing presentation 與 one-page flyer
  'publications',
  // 為 publication manager 而取回的所有模板，但只含特定欄位
  'publicationTemplates',
  // dirty thumbnail
  'isThumbDirty',
]);

/**
 * HTTP request methods
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
 */
export const HTTP_VERB = mirror(['CONNECT', 'DELETE', 'GET', 'HEAD', 'OPTIONS', 'PATCH', 'POST', 'PUT', 'TRACE']);

/**
 * RESTful routing
 * @see https://guides.rubyonrails.org/routing.html
 */
export const REST_ACTION = mirror(['INDEX', 'NEW', 'CREATE', 'SHOW', 'EDIT', 'UPDATE', 'DESTROY']);

/**
 * To accommodate existing action
 * TODO(huge) drop ACTION in favor of REST_ACTION
 */
export enum ACTION {
  LIST = 'list',
  MULTI = 'multi',
  SINGLE = 'single',
  SINGLE_TYPE = 'type',
}

export const BOOK_OP = mirror(['PAGE']);

export const PUBLICATION_OP = mirror(['BOOK_TITLE', 'BOOK', 'PAGE', 'REORDER']);

export const PUBLICATION_KIND = mirror(['MODIFY', 'VIEW', 'DELETE']);

export const BUCKET = {
  THUMBNAIL: 'thumbnail',
  GENERAL: 'general',
  TEMPLATE: 'template',
  PUBLICATION: 'publication',
  LISTING_PRESENTATION: 'listing-presentation',
  SOCIAL_MEDIA: 'social-media',
};

export enum TEMPLATE_FIELD_TYPE {
  TEXT = 'string',
  IMAGE = 'image',
}

export const TEMPLATE_OP = mirror([
  'LABEL',
  'FIELD',
  'LOCK_FIELD',
  'REF_SCHEMA_TYPE',
  'REF_SCHEMA',
  'LIST',
  'SINGLE',
  'DELETE_FIELD',
  'TYPE_CHANGE',
]);

export enum TEMPLATE_KIND {
  'UPDATE_DATA' = 'UPDATE_DATA',
  'LABEL' = 'LABEL',
  'FIELD' = 'FIELD',
  'LOCK_FIELD' = 'LOCK_FIELD',
  'REF_SCHEMA_TYPE' = 'REF_SCHEMA_TYPE',
  'REF_SCHEMA' = 'REF_SCHEMA',
  'TEMPLATE_NAME' = 'TEMPLATE_NAME',
  'THUMBNAIL' = 'THUMBNAIL',
  'TYPE_CHANGE' = 'TYPE_CHANGE',
  'DELETE_FIELD' = 'DELETE_FIELD',
}

export enum SCHEMA_TYPES {
  'HOUSE' = 'HOUSE',
  'AGENT' = 'AGENT',
}

export const API_SCHEMA = {
  HOUSE: [
    'address',
    'bath',
    'buildingProject',
    'city',
    'commonSpace',
    'displayAddress',
    'displayShortID',
    'district',
    'favorites',
    'itemFeature.content',
    'itemFeature.tags',
    'picture.imageUrl',
    'picture.largeImageUrl',
    'price',
    'room',
    'status',
    'title',
    'totalFloorArea',
  ],
  AGENT: [
    'active',
    'bio',
    'chinese_name',
    'contact.fb',
    'contact.ig',
    'contact.line',
    'contact.phone',
    'email',
    'english_name',
    'license',
    'name',
    'pictures',
  ],
};

export const AD_ACTION = {
  CALL_TO_ACTION: 'LEARN_MORE',
  DONE: 'DONE',
};

export const SYNC_IMAGE = {
  UNCATEGORY: 'UNCATEGORY',
  TIMEOUT: 'TIMEOUT',
  LISTING_NOT_FOUND: 'LISTING_NOT_FOUND',
  LISTING_FOLDERID_NOT_FOUND: 'LISTING_FOLDERID_NOT_FOUND',
};

export const LISTING_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  SOLD: 'sold',
};

export const LISTING_STATE = {
  ACTIVE: 'active',
  EXCLUSIVE: 'exclusive',
  INACTIVE: 'inactive',
  NEW: 'new',
  OPEN_HOUSE: 'open-house',
  PRIVATE: 'private',
  SOLD: 'sold',
  PENDING: 'pending',
};

export const customMapStyle = [
  {
    featureType: 'landscape',
    stylers: [{ color: '#E0E0E0' }],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'all',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ lightness: 50 }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [{ visibility: 'Simplified' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'Geometry',
    stylers: [{ color: '#E0E0E0' }],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [{ visibility: 'Simplified' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    elementType: 'Geometry',
    stylers: [{ lightness: 50 }],
  },
];
